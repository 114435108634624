<template>
  <v-container>
    <v-flex>
      <h3>Ocorrências</h3>
    </v-flex>
    <br />
    <v-flex>
      <v-data-table
      dense
        :headers="headers"
        :items="items"
        :items-per-page="20"
        :search="search"
        no-data-text="Ainda foram encontradas ocorrências"
        :loading="showLoading"
        loading-text="Buscando ocorrências..."
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ dmy(props.item.created_at) }}</td>
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.student }}</td>
            <td class="text-xs-left">{{ props.item.course }}</td>
            <td class="text-xs-left">{{ props.item.text }}</td>
            <td class="text-xs-left">{{ props.item.occurrenceType }}</td>
            <td class="text-xs-left">{{ props.item.username }}</td>
          </tr>
        </template>
        -->
      </v-data-table>
    </v-flex>
  </v-container>
</template>


<script>
import { mapState, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  data() {
    return {
      items: [],
      showLoading: false,
      disabled: false,
      search: "",
      headers: [
        {
          sortable: true,
          text: "Data",
          value: "created_at",
          align: "left"
        },
          {
            sortable: true,
            text: "RA",
            value: "academicCode",
            align: "left"
          },
        {
          sortable: true,
          text: "Aluno",
          value: "student",
          align: "left"
        },
        
        {
          sortable: true,
          text: "Curso",
          value: "course",
          align: "left"
        },

        {
          sortable: true,
          text: "Ocorrência",
          value: "text",
          align: "left"
        },
        {
          sortable: true,
          text: "Tipo",
          value: "occurrenceType",
          align: "left"
        },
        {
          sortable: true,
          text: "Criada por",
          value: "username",
          align: "left"
        }
      ]
    };
  },

  created() {
    if(this.occurrences != null && this.occurrences.length > 0){
      this.updateDataTable(this.occurrences)
    } else {
      this.asyncGetOccurrences();
    }
  },

  computed: {
    ...mapState("adminOccurrence", ["occurrences"])
  },

  methods: {
    ...mapActions("adminOccurrence", ["ActionGetOccurrences", "ActionPostOccurrence"]),

    async asyncGetOccurrences() {
      this.showLoading = true;
      try {
        await this.ActionGetOccurrences();
      } catch (err) {
        console.error("Erro ao buscar as atualizações", err);
      } finally{
        this.showLoading = false;
      }
    },
    
    updateDataTable(items){
      this.items = [];
      if(items && items.length > 0){
        items.map(e => {
          this.items.push(
            {
              id: e.id,
              created_at: e.createdAt,
              academicCode: e.student.academicCode,
              student: e.student.name,
              text: e.text,
              occurrenceType: e.occurrenceTypeTO.name,
              username: e.user.name,
              course: e.course.name
            }
          )          
        });
      }
    },

     dmy(date){
      return formatDate.dmy(date);
    }
  },

  watch: {
    occurrences(newValue) {
     this.updateDataTable(newValue)
    } 
  }
};
</script>
