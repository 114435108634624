var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-flex", [_c("h3", [_vm._v("Ocorrências")])]),
      _c("br"),
      _c(
        "v-flex",
        [
          _c(
            "v-data-table",
            {
              attrs: {
                dense: "",
                headers: _vm.headers,
                items: _vm.items,
                "items-per-page": 20,
                search: _vm.search,
                "no-data-text": "Ainda foram encontradas ocorrências",
                loading: _vm.showLoading,
                "loading-text": "Buscando ocorrências..."
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(props) {
                    return [
                      _c("tr", [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(_vm.dmy(props.item.created_at)))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.academicCode))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.student))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.course))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.text))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.occurrenceType))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.username))
                        ])
                      ])
                    ]
                  }
                }
              ])
            },
            [_vm._v(" --> ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }